// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-js": () => import("./../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-about-js": () => import("./../src/pages/about.js" /* webpackChunkName: "component---src-pages-about-js" */),
  "component---src-pages-blog-js": () => import("./../src/pages/blog.js" /* webpackChunkName: "component---src-pages-blog-js" */),
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-cookie-declaration-js": () => import("./../src/pages/cookie-declaration.js" /* webpackChunkName: "component---src-pages-cookie-declaration-js" */),
  "component---src-pages-guides-js": () => import("./../src/pages/guides.js" /* webpackChunkName: "component---src-pages-guides-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-privacy-policy-js": () => import("./../src/pages/privacy-policy.js" /* webpackChunkName: "component---src-pages-privacy-policy-js" */),
  "component---src-templates-blog-post-js": () => import("./../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */),
  "component---src-templates-category-js": () => import("./../src/templates/category.js" /* webpackChunkName: "component---src-templates-category-js" */),
  "component---src-templates-guides-post-js": () => import("./../src/templates/guidesPost.js" /* webpackChunkName: "component---src-templates-guides-post-js" */)
}

